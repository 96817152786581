<template>
  <div>
    <!-- 上半部 -->
    <div id="ParticipantTopArea" class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1 d-flex flex-wrap align-items-center">
        <!-- 批次操作 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="ParticipantBatch"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            批次操作
          </button>
          <ul class="dropdown-menu" aria-labelledby="ParticipantBatch">
            <li>
              <button class="dropdown-item" @click="showModal('verify')">
                審核
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('del')">
                刪除
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('restore')">
                復原
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('batchCheckOut')">
                批次結單
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('updateWallet')">
                新增購物金
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('setVip')">
                設定VIP等級
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('batchSetVip')">
                批次轉換VIP身分
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('batchSetCustomerGroup')"
              >
                批次轉換顧客群組
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('mergeParticipant', 'p_merge_v')"
              >
                <span class="text-primary fw-bolder">實體顧客</span> 併 <span class="text-danger fw-bolder">虛擬顧客</span>
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('mergeParticipant', 'v_merge_v')"
              >
                <span class="text-danger fw-bolder">虛擬顧客</span> 併 <span class="text-danger fw-bolder">虛擬顧客</span>
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('mergeParticipant', 's_merge_s')"
              >
                <span class="text-success fw-bolder">社群顧客</span> 併 <span class="text-success fw-bolder">社群顧客</span>
              </button>
            </li>
            <li v-if="mergeFbExtensionVirtualUser">
              <button
                class="dropdown-item"
                @click="showModal('mergeParticipantForFbSameName')"
              >
                一鍵合併 (FB 為主)
              </button>
            </li>
          </ul>
        </div>
        <!-- 執行 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="ParticipantAction"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            執行
          </button>
          <ul class="dropdown-menu" aria-labelledby="ParticipantAction">
            <li>
              <button class="dropdown-item" @click="showModal('exportExcel')">
                匯出
              </button>
            </li>
          </ul>
        </div>
        <!-- 通知 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="ParticipantNotice"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            通知
          </button>
          <ul class="dropdown-menu" aria-labelledby="ParticipantNotice">
            <li>
              <button
                class="dropdown-item"
                @click="showModal('lineNotifyGeneral')"
              >
                Line Notify通知 (群發)
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('lineNotifyGroupA')"
              >
                Line Notify通知 (群發：已配單但未結單)
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('lineNotifyGroupB')"
              >
                Line Notify通知 (群發：已結單但未付款)
              </button>
            </li>
          </ul>
        </div>
        <!-- 新增虛擬顧客 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('virtualCustomer')"
        >
          新增虛擬顧客
        </button>
        <!-- 常用搜尋 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="FrequentlySearched"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            常用搜尋
          </button>
          <ul class="dropdown-menu" aria-labelledby="FrequentlySearched">
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('已刪除')"
              >
                已刪除
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('審核未通過')"
              >
                審核未通過
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('尚未審核')"
              >
                尚未審核
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('已配未結 > 0')"
              >
                已配未結 > 0
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('全到貨')"
              >
                全到貨
              </button>
            </li>
          </ul>
        </div>
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
        <!-- 前往舊版顧客管理 -->
        <!-- <router-link
          class="me-1 mb-1 tw-btn tw-btn-danger tw-btn-hover"
          :to="`/seller/store/${storeId}/oldParticipant`"
          target="_blank"
        >
          前往舊版顧客管理
        </router-link> -->
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <p>
          搜尋條件:
          <span class="badge bg-primary me-1 mb-1">
            顧客狀態:
            {{ recordAdvancedSearchData.singleSelect.participantStatus }}
          </span>
          <span class="badge bg-primary me-1 mb-1">
            合併狀態:
            {{ recordAdvancedSearchData.singleSelect.mergeStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.allocatedAndCheckout !==
              '不選擇'
            "
          >
            配單/結單:
            {{ recordAdvancedSearchData.singleSelect.allocatedAndCheckout }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.uncheckoutStatus !==
              '不選擇'
            "
          >
            未結單金額狀態:
            {{ recordAdvancedSearchData.singleSelect.uncheckoutStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.walletStatus !== '不選擇'
            "
          >
            購物金餘額:
            {{ recordAdvancedSearchData.singleSelect.walletStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.singleSelect.vipStatus !== '不選擇'"
          >
            顧客 VIP:
            {{ recordAdvancedSearchData.singleSelect.vipStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.participantHabitA !==
              '不選擇'
            "
          >
            顧客習性 1:
            {{ recordAdvancedSearchData.singleSelect.participantHabitA }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.participantHabitB !==
              '不選擇'
            "
          >
            顧客習性 2:
            {{ recordAdvancedSearchData.singleSelect.participantHabitB }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.arrivalStatus !==
              '不選擇'
            "
          >
            到貨狀態:
            {{ recordAdvancedSearchData.singleSelect.arrivalStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.searchByContent !== '不選擇'
            "
          >
            依內容搜尋:
            {{ recordAdvancedSearchData.singleSelect.searchByContent }}
          </span>
        </p>
        <p>
          <span v-if="recordAdvancedSearchData.billTimeRange.switch">
            帳單區間:
            <span
              v-if="
                recordAdvancedSearchData.billTimeRange.startTime &&
                recordAdvancedSearchData.billTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.billTimeRange.startTime }}~{{
                recordAdvancedSearchData.billTimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.billTimeRange.startTime &&
                !recordAdvancedSearchData.billTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.billTimeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.billTimeRange.startTime &&
                recordAdvancedSearchData.billTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.billTimeRange.endTime }}以前</span
            >&ensp;
          </span>
          <span v-if="recordAdvancedSearchData.orderTimeRange.switch">
            訂單區間:
            <span
              v-if="
                recordAdvancedSearchData.orderTimeRange.startTime &&
                recordAdvancedSearchData.orderTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.orderTimeRange.startTime }}~{{
                recordAdvancedSearchData.orderTimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.orderTimeRange.startTime &&
                !recordAdvancedSearchData.orderTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.orderTimeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.orderTimeRange.startTime &&
                recordAdvancedSearchData.orderTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.orderTimeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </div>
    </div>
    <!-- OK(差帳單區間) dataTable -->
    <div class="tw-container">
      <div class="management-datatable">
        <DataTable
          class="p-datatable-sm"
          :scrollHeight="`${scrollHeight}px`"
          :value="participantList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="D4Row"
          :rowsPerPageOptions="[20, 50, 100]"
          :scrollable="true"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="menue"
          stateStorage="local"
          stateKey="dt-state-participant-local"
          sortField="verifyDate"
          :sortOrder="1"
          v-model:selection="selectItems"
        >
          <template #header>
            <div class="d-flex align-items-center">
              <FieldFilter
                whitchOneTable="participant"
                :D4FieldFilter="fieldFilter"
              ></FieldFilter>
              <div class="w-100">
                <p>表格搜尋(請按Enter或是點擊搜尋按鈕)</p>
                <div class="d-flex">
                  <button
                    class="me-1 mb-1 tw-btn tw-btn-success tw-btn-hover"
                    @click="tableSearch"
                  >
                    搜尋
                  </button>
                  <input
                    class="form-control me-2"
                    placeholder="表格搜尋: 顧客姓名，未配，已配未結，未付款，未寄出，購物金，未結單金額，最後登入時間"
                    v-model="tableSearchValue"
                    @keyup.enter="tableSearch"
                    style="max-width: 99%"
                  />
                </div>
              </div>
            </div>
          </template>
          <!-- 選取 -->
          <Column
            v-if="fieldFilter[0].selected"
            selectionMode="multiple"
            style="max-width: 50px"
          ></Column>
          <!-- 大頭照 -->
          <Column
            v-if="fieldFilter[1].selected"
            field=""
            header="大頭照"
            style="min-width: 68px; max-width: 68px"
          >
            <template #body="{ data }">
              <!-- 是否為合併帳號 -->
              <p class="mb-2 fw-bolder">
                <span class="border border-success text-success" v-if="data.user.slaveId">主要帳</span>
                <span class="border border-danger text-danger" v-if="data.user.masterId">被併帳</span>
              </p>
              <!-- 顯示 line / fb 大頭貼 -->
              <div v-if="data.line.profilePicture || data.fb.profilePicture">
                <Image
                  class="table-img border border-primary border-3"
                  :url="data.fb.profilePicture"
                  v-if="data.fb.profilePicture"
                  :alt="data.fb.profilePicture"
                ></Image>
                <Image
                  class="table-img border border-primary border-3"
                  :url="data.line.profilePicture"
                  v-if="data.line.profilePicture"
                  :alt="data.line.profilePicture"
                ></Image>
              </div>
              <img
                class="table-img"
                v-else
                src="@/assets/other-images/nobody.png"
                alt="nobody"
              />
            </template>
          </Column>
          <!-- 狀態 -->
          <Column
            v-if="fieldFilter[2].selected"
            field=""
            header="狀態"
            style="min-width: 50px; max-width: 50px"
          >
            <template #body="{ data }">
              <!-- 虛擬顧客 icon -->
              <div v-if="data.userType === 4">
                <img
                  class="icon me-1"
                  title="虛擬顧客"
                  src="@/assets/icon/user.png"
                  alt=""
                />
              </div>
              <!-- 社群綁定 icon -->
              <div>
                <img
                  class="icon me-1"
                  title="顧客有綁定FB帳號"
                  src="@/assets/icon/facebook-2.jpg"
                  alt="顧客有綁定FB帳號"
                  v-if="data.fb.bindingFb"
                />

                <img
                  class="icon me-1"
                  title="顧客有綁定Line帳號"
                  src="@/assets/icon/line-icon.png"
                  alt=""
                  v-if="data.line.bindingLine"
                />

                <img
                  class="icon"
                  title="顧客有綁定Line Notify"
                  src="@/assets/icon/linenotify.png"
                  alt=""
                  v-if="data.line.bindingLineNotify"
                />
              </div>
            </template>
          </Column>
          <!-- 顧客 -->
          <Column
            v-if="fieldFilter[3].selected"
            field="user.name"
            header="顧客"
            sortable
            style="min-width: 200px; max-width: 200px"
          >
            <template #body="{ data }">
              <!-- 審核禁止狀態 -->
              <div class="mb-1">
                <p
                  class="badge bg-warning text-dark m-0 pointer can-click"
                  v-if="data.verify === null"
                  @click="showModal('verify', data)"
                >
                  待審核
                </p>
                <p
                  class="badge bg-danger can-click m-0"
                  v-if="data.verify === false"
                  @click="showModal('verify', data)"
                >
                  審核不通過
                </p>
                <p
                  class="text-danger tw-text-size12 m-0 pointer can-click"
                  @click="showModal('setForbidden', data)"
                  v-if="data.forbidden"
                >
                  禁止個人賣場
                </p>
              </div>
              <!-- 顧客資訊 -->
              <div class="mb-1">
                <span class="me-2 tw-text-size14">顧客ID：{{ data.id }}</span>
                <span class="text-danger" v-if="data.deleted">(刪除)</span>
              </div>
              <!-- 顧客資訊 -->
              <div class="mb-1">
                <router-link
                  :to="`/seller/store/${storeId}/participant/${data.id}/merchOrder`"
                  class="text-primary fw-bolder"
                  target="_blank"
                  >{{ data.user.name }}</router-link
                >
                <span
                  class="tw-text-size14 text-success pointer can-click"
                  title="顧客暱稱"
                  v-if="data.nickName"
                  @click="showModal('editNickName', data)"
                >
                  | {{ data.nickName }}</span
                >
              </div>
              <!-- line -->
              <div class="mb-1" v-if="data.line.bindingLine">
                <p>
                  <img
                    class="icon"
                    title="顧客的 Line 名稱"
                    src="@/assets/icon/line-icon.png"
                    alt="顧客的 Line 名稱"
                  />&ensp;{{ data.line.name }}
                </p>
              </div>
              <!-- fb -->
              <div class="mb-1" v-if="data.fb.name">
                <p>
                  <img class="icon" src="@/assets/icon/facebook-2.jpg" title="顧客的 Facebook 名稱" alt="顧客的 Facebook 名稱" />&ensp;{{ data.fb.name }}
                  <span class="badge rounded-pill bg-danger" v-if="data.fb.personId && data.userType === 4">擴</span>
                </p>
              </div>
              <!-- vip -->
              <div class="mb-1" title="顧客的VIP" v-if="data.storeVip.id">
                <img class="icon me-1" src="@/assets/icon/crown1.png" alt="顧客的VIP" />
                <span
                  class="tw-text-size14 pointer can-click"
                  @click="showModal('setVip', data)"
                  >VIP{{ data.storeVip.level }}：{{ data.storeVip.name }}</span
                >
              </div>
            </template>
          </Column>
          <!-- 顧客群組 -->
          <Column
            v-if="fieldFilter[4].selected"
            field="customerGroup.id"
            header="顧客群組"
            sortable
            style="min-width: 75px; max-width: 75px"
            class="text-center"
          >
            <template #body="{ data }">
              <div
                class="pointer can-click"
                @click="showModal('editCustomerGroup', data)"
              >
                <span v-if="data.customerGroup.id">{{
                  data.customerGroup.name
                }}</span>
                <span v-else>無</span>
              </div>
            </template>
          </Column>
          <!-- 操作 -->
          <Column
            v-if="fieldFilter[5].selected"
            field=""
            header="操作"
            style="min-width: 40px"
          >
            <template #body="{ data }">
              <div class="dropdown dropend pointer">
                <img
                  src="@/assets/icon/settings.png"
                  alt=""
                  id="Setting"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="width: 25px; height: 25px"
                />
                <ul class="dropdown-menu" aria-labelledby="Setting">
                  <li v-if="data.userType === 4">
                    <button
                      class="dropdown-item"
                      @click="showModal('editName', data)"
                    >
                      編輯名稱
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="showModal('editNickName', data)"
                    >
                      編輯暱稱
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="showModal('setVip', data)"
                    >
                      設定VIP等級
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="showModal('setForbidden', data)"
                    >
                      禁止/開放 個人賣場
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      v-if="data.verify === null"
                      @click="showModal('verify', data)"
                    >
                      審核
                    </button>
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/seller/store/${storeId}/checkoutOrder/${data.id}?status=preCheckout`"
                      >結單</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/seller/store/${storeId}/checkoutOrder/${data.id}?status=allocatedCheckout`"
                      class="dropdown-item"
                      >已配商品結單</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/seller/store/${storeId}/participant/${data.id}/merchOrder`"
                      class="dropdown-item"
                      >顧客詳細資訊</router-link
                    >
                  </li>
                </ul>
              </div>
            </template>
          </Column>
          <!-- 未配 -->
          <Column
            v-if="fieldFilter[6].selected"
            field="unAllocatedQuantity"
            header="未配"
            style="min-width: 60px"
            sortable
          >
            <template #body="{ data }">
              <p>{{ data.unAllocatedQuantity }}</p>
            </template>
          </Column>
          <!-- 已配未結 -->
          <Column
            v-if="fieldFilter[7].selected"
            field="allocatedButUnCheckOutQuantity"
            header="已配未結"
            style="min-width: 75px; max-width: 75px"
            sortable
          >
            <template #body="{ data }">
              <p>{{ data.allocatedButUnCheckOutQuantity }}</p>
            </template>
          </Column>
          <!-- 未付款 -->
          <Column
            v-if="fieldFilter[8].selected"
            field="unPaidQuantity"
            header="未付款"
            style="min-width: 60px"
            sortable
          >
            <template #body="{ data }">
              <p>{{ data.unPaidQuantity }}</p>
            </template>
          </Column>
          <!-- 未寄出 -->
          <Column
            v-if="fieldFilter[9].selected"
            field="unShippedQuantity"
            header="未寄出"
            style="min-width: 60px"
            sortable
          >
            <template #body="{ data }">
              <p>{{ data.unShippedQuantity }}</p>
            </template>
          </Column>
          <!-- 購物金 -->
          <Column
            v-if="fieldFilter[10].selected"
            field="wallet"
            header="購物金"
            style="min-width: 60px"
            sortable
          >
            <template #body="{ data }">
              <p
                class="pointer can-click"
                @click="showModal('updateWallet', data)"
              >
                {{ data.wallet }}
              </p>
            </template>
          </Column>
          <!-- 未結單金額 -->
          <Column
            v-if="fieldFilter[11].selected"
            field="unCheckOutMoney"
            header="未結單金額"
            style="min-width: 90px; max-width: 90px"
            sortable
          >
            <template #body="{ data }">
              <p>{{ data.unCheckOutMoney }}</p>
            </template>
          </Column>
          <!-- 區間金額 -->
          <Column
            v-if="fieldFilter[12].selected"
            :field="recordAdvancedSearchData.showBillMoney ? 'moneyRangeBill' : 'moneyRangeOrder'"
            header="區間金額"
            style="min-width: 120px; max-width: 120px"
            sortable
          >
            <template #body="{ data }">
              <template v-if="recordAdvancedSearchData.showBillMoney">
                <p class="spinner-border spinner-border-sm" v-if="tableAreaLoading"></p>
                <p v-else>帳單：{{ data.moneyRangeBill }}</p>
              </template>
              <p class="mb-1">訂單：{{ data.moneyRangeOrder }}</p>
            </template>
          </Column>
          <!-- 時間 -->
          <Column
            v-if="fieldFilter[13].selected"
            field="verifyDate"
            header="時間"
            sortable
            style="min-width: 245px"
          >
            <template #body="{ data }">
              <p class="mb-2">最後登入：{{ data.lastLogin }}</p>
              <p>加入時間：{{ data.verifyDate }}</p>
            </template>
          </Column>
          <template #empty>
            <p class="text-center fw-bolder text-primary">
              目前尚未有任何資料 0..0
            </p>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- OK 新增虛擬顧客 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="virtualCustomerModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">新增虛擬顧客</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="createVirtualCustomer"
              placeholder="輸入虛擬顧客"
              maxlength="15"
              v-model="name"
            />
            <label for="createVirtualCustomer">輸入虛擬顧客(15字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createVirtualUser"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 合併顧客 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="mergeParticipantModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            <span v-if="mergeParticipantStatus === 'p_merge_v'">實體顧客 併 虛擬顧客</span>
            <span v-else-if="mergeParticipantStatus === 'v_merge_v'">虛擬顧客 併 虛擬顧客</span>
            <span v-else-if="mergeParticipantStatus === 's_merge_s'">社群顧客 併 社群顧客</span>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="fw-bolder mb-3">
            <div class="row" v-if="selectItems.length === 1">
              <!-- 主帳號 -->
              <p class="col-5 p-0 px-1 py-3 alert alert-primary text-center">
                <p>主帳號</p>
                <hr>
                <p>{{ selectItems[0].user.name }}</p>
              </p>
              <!-- center -->
              <p class="d-flex flex-column justify-content-center align-items-center col-2 p-0">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rewind-fill" viewBox="0 0 16 16">
                    <path d="M8.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L8.404 7.304Z"/>
                    <path d="M.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L.404 7.304Z"/>
                  </svg>
                </p>
                <p>合併至</p>
              </p>
              <!-- 被合併帳號 -->
              <p
                class="col-5 p-0 px-1 py-3 alert text-center"
                :class="{
                  'alert-success': mergePhysicalUserChoose && mergePhysicalUserChoose.user.name,
                  'alert-danger': mergePhysicalUserChoose === null
                }"
              >
                <p>被合併帳號</p>
                <p class="text-danger mt-2" v-if="mergeParticipantStatus !== 's_merge_s'">(合併後此帳號將會被刪除)</p>
                <hr>
                <p>
                  <span v-if="mergePhysicalUserChoose && mergePhysicalUserChoose.user.name">{{ mergePhysicalUserChoose.user.name }}</span>
                  <span v-else>未選擇</span>
                </p>
              </p>
            </div>
          </div>
          <p class="fw-bolder mb-3 ms-1">
            選擇被合併帳號 <i class="bi bi-arrow-down-square-fill"></i>
          </p>
          <!-- 搜尋 -->
          <input
            type="text"
            class="form-control mb-3"
            placeholder="輸入搜尋值"
            v-model="mergePhysicalUserSearch"
          />
          <!-- 顧客列表 -->
          <div
            class="table-responsive"
            style="height: 350px; overflow-y: scroll"
          >
            <table class="table table-hover">
              <thead class="float-header">
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>顧客姓名</th>
                  <th>暱稱</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="physicalUser in serchPhysicalUserData"
                  :key="physicalUser.id"
                >
                  <td>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="physicalUserChoose"
                        :value="physicalUser"
                        v-model="mergePhysicalUserChoose"
                      />
                    </div>
                  </td>
                  <td>{{ physicalUser.id }}</td>
                  <td>
                    (
                    <span
                      class="text-success"
                      v-if="physicalUser.userType === 1"
                      >實體</span
                    >
                    <span class="text-danger" v-else>虛擬</span>
                    )
                    {{ physicalUser.user.name }}
                  </td>
                  <td>{{ physicalUser.nickName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="mergeParticipant"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 編輯暱稱 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editNickNameModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯暱稱</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditNick"
              placeholder="輸入暱稱"
              maxlength="15"
              v-model="name"
            />
            <label for="EditNick">輸入暱稱(15字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="editNickName"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 編輯名稱 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editNameModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯名稱</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditName"
              placeholder="輸入名稱"
              maxlength="15"
              v-model="name"
            />
            <label for="EditName">輸入名稱(15字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="editName"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 編輯顧客群組 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editCustomerGroupModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯顧客群組</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AreaLoading v-if="customerGroups.loading"></AreaLoading>
          <select
            class="form-select mb-3"
            id="setCustomerGroup"
            v-model="selectCustomerGroupId"
            v-else
          >
            <option value="0">取消顧客群組</option>
            <option
              :value="data.id"
              v-for="data in customerGroups.data"
              :key="data.id"
            >
              {{ data.name }}
            </option>
          </select>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setCustomerGroup"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 設定VIP等級 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setVipModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">設定VIP等級</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos
            :propsSelectInfos="propsSelectInfos"
            v-if="isBatch"
          ></SelectInfos>
          <AreaLoading v-if="storeVips.loading"></AreaLoading>
          <select
            class="form-select mb-3"
            id="setVipGrate"
            v-model="selectVip"
            v-else
          >
            <option value="0">取消VIP等級</option>
            <template v-for="(data, index) in storeVips.data" :key="data.id">
              <option :value="data.id" v-if="data.id <= storeInfo.storeVipId">
                LV{{ index + 1 }}: {{ data.name }}
              </option>
            </template>
          </select>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="setVip">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 禁止/開放個人賣場 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setForbiddenModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            禁止/開放個人賣場
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <button class="tw-btn tw-btn-danger me-2" @click="setForbidden(true)">
            禁止
          </button>
          <button class="tw-btn tw-btn-success" @click="setForbidden(false)">
            開放
          </button>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary me-2"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 審核 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="verifyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">審核</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="fw-bolder">
            審核&ensp;
            <button class="tw-btn tw-btn-danger me-2" @click="setVerify(false)">
              不通過
            </button>
            <button class="tw-btn tw-btn-success" @click="setVerify(true)">
              通過
            </button>&ensp;
            這些顧客
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary me-2"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 新增購物金 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="updateWalletModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">新增購物金
            <a href="https://youtu.be/-S22idejHrM?si=Ya-iP_I53Ihqrtj-" target="_blank">
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 金額調整 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="UpdateWallet"
              placeholder="金額調整"
              v-model="updateWalletData.adjustment"
              @change="
                updateWalletData.adjustment = $methods.numberToFixed(
                  updateWalletData.adjustment
                )
              "
            />
            <label for="UpdateWallet">金額調整</label>
          </div>
          <!-- 備註 -->
          <div class="mb-3">
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                placeholder="備註"
                id="batchAddWalletNote"
                style="height: 150px"
                maxlength="300"
                v-model="updateWalletData.note"
              ></textarea>
              <label for="batchAddWalletNote">備註(300字)</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateWallet"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 批次結單 modal -->
  <BatchCheckOut
    :propsSelectInfos="propsSelectInfos"
    :showBatchCheckOutModal="showBatchCheckOutModal"
    @notifyParentFromBatchCheckOut="notifyParentFromBatchCheckOut"
  ></BatchCheckOut>
  <!-- OK 批次轉換VIP身分 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchSetVipModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">批次轉換VIP身分</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="text-danger mb-3">*此步驟會將符合條件的顧客全部進行轉換</p>
          <AreaLoading v-if="storeVips.loading"></AreaLoading>
          <div v-else>
            <span class="me-2">從</span>
            <!-- 轉換前VIP身分 -->
            <select
              class="form-select w-25 d-inline-block me-2"
              id="bBatchSetVipGrate"
              v-model="batchOperate.before"
            >
              <option value="" disabled selected>選擇VIP身分</option>
              <option value="null">非VIP身分</option>
              <template v-for="(data, index) in storeVips.data" :key="data.id">
                <option :value="data.id" v-if="data.id <= storeInfo.storeVipId">
                  LV{{ index + 1 }}: {{ data.name }}
                </option>
              </template>
            </select>
            <span class="me-2">轉換成</span>
            <!-- 轉換後VIP身分 -->
            <select
              class="form-select w-25 d-inline-block me-2"
              id="aBatchSetVipGrate"
              v-model="batchOperate.after"
            >
              <option value="" disabled selected>選擇VIP等級</option>
              <option value="0">取消VIP等級</option>
              <template v-for="(data, index) in storeVips.data" :key="data.id">
                <option :value="data.id" v-if="data.id <= storeInfo.storeVipId">
                  LV{{ index + 1 }}: {{ data.name }}
                </option>
              </template>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="batchSetVip"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 批次轉換顧客群組 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchSetCustomerGroupModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            批次轉換顧客群組
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="text-danger mb-3">*此步驟會將符合條件的顧客全部進行轉換</p>
          <AreaLoading v-if="customerGroups.loading"></AreaLoading>
          <div v-else>
            <span class="me-2">從</span>
            <select
              class="form-select w-25 d-inline-block me-2"
              id="bBatchsetCustomerGroup"
              v-model="batchOperate.before"
            >
              <option value="" disabled selected>選擇顧客群組</option>
              <option value="null">非顧客群組</option>
              <option
                :value="data.id"
                v-for="data in customerGroups.data"
                :key="data.id"
              >
                {{ data.name }}
              </option>
            </select>
            <span class="me-2">轉換成</span>
            <select
              class="form-select w-25 d-inline-block me-2"
              id="aBatchsetCustomerGroup"
              v-model="batchOperate.after"
            >
              <option value="" disabled selected>選擇顧客群組</option>
              <option value="0">取消顧客群組</option>
              <option
                :value="data.id"
                v-for="data in customerGroups.data"
                :key="data.id"
              >
                {{ data.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="batchSetCustomerGroup"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 匯出 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="exportExcelModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">匯出 Excel</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>是否將所選擇到的訂單匯出成 Excel 格式 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="exportExcel"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 刪除 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bolder
            "
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="text-danger">*確認刪除這些顧客</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setDeleted(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 復原 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="restoreModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-primary">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-primary
              text-primary
              fw-bolder
            "
          >
            復原
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="text-primary">是否確定恢復這些顧客?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setDeleted(false)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK LineNotify 通知 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="notifyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-if="notifyStatus === 'general'"
          >
            Line Notify通知 (群發)
          </h5>
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-else-if="notifyStatus === 'groupA'"
          >
            Line Notify通知 (群發：已配單但未結單)
          </h5>
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-else-if="notifyStatus === 'groupB'"
          >
            Line Notify通知 (群發：已結單但未付款)
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 提醒訊息 -->
          <div>
            <p class="mb-2">
              系統將會發送訊息給所有<span class="text-success fw-bolder"
                >有啟動LINE Notify</span
              >的顧客
            </p>
            <p class="mb-3">為避免混淆，發送的訊息皆會註明是由賣家發出</p>
            <!-- 只有(已配單但未結單、已結單但未付款)有 -->
            <div v-if="notifyStatus !== 'general'">
              <p class="mb-3">
                將會發送訊息給所有
                <span
                  class="text-danger fw-bolder"
                  v-if="notifyStatus === 'groupA'"
                  >已配單但未結單</span
                >
                <span
                  class="text-danger fw-bolder"
                  v-else-if="notifyStatus === 'groupB'"
                  >已結單但未付款</span
                >
                的顧客
              </p>
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  v-model="checkIsSetOwnNotifyMsg"
                  id="checkIsSetOwnNotifyMsg"
                />
                <label class="form-check-label" for="checkIsSetOwnNotifyMsg">
                  選擇自行輸入內容
                </label>
              </div>
            </div>
          </div>
          <!-- 備註(兩種型態) -->
          <div class="mb-3">
            <!-- 第一種:一般型態 -->
            <div v-if="checkIsSetOwnNotifyMsg">
              <div class="form-floating mb-3">
                <textarea
                  class="form-control"
                  placeholder="備註"
                  id="batchAddWalletNote"
                  style="height: 150px"
                  maxlength="300"
                  v-model="notifyMsg"
                ></textarea>
                <label for="batchAddWalletNote">備註(300字)</label>
              </div>
            </div>
            <!-- 第二種:(已配單但未結單、已結單但未付款) 勾選「選擇自行輸入內容」 -->
            <div class="tw-container border border-1 m-0" v-else>
              <p class="fw-bolder">
                系統預設訊息：
                <span class="text-secondary" v-if="notifyStatus === 'groupA'"
                  >XX您好，您有已配單但未結單</span
                >
                <span
                  class="text-secondary"
                  v-else-if="notifyStatus === 'groupB'"
                  >XX您好，您有已結單但未付款</span
                >
              </p>
            </div>
          </div>
          <!-- 上傳圖片 -->
          <div class="mb-3">
            <div class="input-group mb-3">
              <input
                type="file"
                class="form-control"
                accept="image/png, image/jpeg"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="sendNotify"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 表格可點選區介紹 modal -->
  <div
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="introductionModal"
  >
    <div class="modal-dialog" :style="scrollWidth">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">表格可點選區域</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <img
              src="@/assets/images/table-introduction/participant.jpg"
              style="max-width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 一鍵合併 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="mergeParticipantForFbSameNameModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">一鍵合併</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            v-if="!loadingText"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="!loadingText">
            <div class="mb-3 text-end" v-if="sameFbNameParticipants.length > 0">
              <button type="button" class="tw-btn tw-btn-success" @click="startToMergeFbVirtualUser">確認合併</button>
            </div>
            <table class="table table-hover" v-if="sameFbNameParticipants.length > 0">
              <thead>
                <tr>
                  <th style="min-width: 100px; max-width: 100px">名稱</th>
                  <th style="width: 50%">實體顧客</th>
                  <th style="width: 50%">虛擬顧客</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(data, index) in sameFbNameParticipants" :key="data.sameName">
                  <tr :class="{'alert-danger': data.physicalUsers.length > 1 || data.virtualUsers.length > 1}" v-if="!data.deleted">
                    <td>
                      <p class="fw-bolder mb-2">{{ data.sameName }}</p>
                      <button class="tw-btn tw-btn-danger" @click="deleteMergeFbVirtualUser(index)">刪除</button>
                    </td>
                    <!-- 實體顧客 -->
                    <td>
                      <div class="d-flex mb-2" v-for="physicalUser in data.physicalUsers" :key="`ph_${physicalUser.participantId}`">
                        <div>
                          <Image
                            class="table-img border border-primary border-3"
                            :url="physicalUser.fbProfilePicture"
                            v-if="physicalUser.fbProfilePicture"
                            :alt="physicalUser.fbProfilePicture"
                          ></Image>
                        </div>
                        <div class="text-start">
                          <p>顧客ID: {{ physicalUser.participantId }}</p>
                          <input type="radio" :name="`ph_${index}`" :id="`ph_${physicalUser.participantId}`" v-model="data.masterParticipantId" :value="physicalUser.participantId">
                          <label :for="`ph_${physicalUser.participantId}`">{{ physicalUser.userName }}</label>
                        </div>
                      </div>
                    </td>
                    <!-- 虛擬顧客 -->
                    <td>
                      <div class="d-flex mb-2" v-for="virtualUser in data.virtualUsers" :key="`vi_${virtualUser.participantId}`">
                        <div>
                          <Image
                            class="table-img border border-primary border-3"
                            :url="virtualUser.fbProfilePicture"
                            v-if="virtualUser.fbProfilePicture"
                            :alt="virtualUser.fbProfilePicture"
                          ></Image>
                        </div>
                        <div class="text-start">
                          <p>顧客ID: {{ virtualUser.participantId }} <span class="badge rounded-pill bg-danger" v-if="virtualUser.personId">擴</span></p>
                          <input type="radio" :name="`vi_${index}`" :id="`vi_${virtualUser.participantId}`" v-model="data.slaveParticipantId" :value="virtualUser.participantId">
                          <label :for="`vi_${virtualUser.participantId}`">{{ virtualUser.userName }}</label>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <p class="p-3 fw-bolder text-center alert-danger" v-else>
              沒有可以合併的項目 !
            </p>
          </div>
          <p class="fw-bolder alert-warning p-3 text-center fs-5" v-else>
            {{ loadingText }}
          </p>
        </div>
        <div class="modal-footer" v-if="!loadingText">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="startToMergeFbVirtualUser" v-if="sameFbNameParticipants.length > 0">確認合併</button>
        </div>
      </div>
    </div>
  </div>
  <!-- OK 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="alert alert-danger">
            以下兩種時間區間皆會影響表格中"未配"，"已配未結"，"未付款"，"未寄出"，"未結單金額"與"區間金額"
            !
          </p>
          <!-- 帳單區間金額(有付款) -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_BillTimeSwitch"
                v-model="advancedSearchData.billTimeRange.switch"
              />
              <label class="form-check-label" for="AS_BillTimeSwitch"
                >帳單區間金額(有付款)</label
              >
            </div>
            <!-- 時間 -->
            <div
              class="row mb-3"
              v-if="advancedSearchData.billTimeRange.switch"
            >
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_BillStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.billTimeRange.startTime"
                  />
                  <label for="AS_BillStartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_BillEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.billTimeRange.endTime"
                  />
                  <label for="AS_BillEndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 是否顯示帳單區間金額 -->
          <div class="mb-3">
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_ShowBillMoneySwitch"
                v-model="advancedSearchData.showBillMoney"
              />
              <label class="form-check-label" for="AS_ShowBillMoneySwitch"
                >是否顯示帳單區間金額(有付款)</label
              >
            </div>
          </div>
          <!-- 訂單區間金額 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_OrderTimeSwitch"
                v-model="advancedSearchData.orderTimeRange.switch"
              />
              <label class="form-check-label" for="AS_OrderTimeSwitch"
                >訂單區間金額</label
              >
            </div>
            <!-- 時間 -->
            <div
              class="row mb-3"
              v-if="advancedSearchData.orderTimeRange.switch"
            >
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_OrderStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.orderTimeRange.startTime"
                  />
                  <label for="AS_OrderStartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_OrderEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.orderTimeRange.endTime"
                  />
                  <label for="AS_OrderEndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 顧客群組 -->
          <div class="mb-3">
            <div class="form-floating" v-if="!customerGroups.loading">
              <select
                class="form-select"
                id="AS_Supplier"
                v-model="advancedSearchData.customerGroup"
              >
                <option :value="null">不選擇</option>
                <option
                  v-for="data in customerGroups.data"
                  :value="data"
                  :key="data.id"
                >
                  {{ data.name }}
                </option>
              </select>
              <label for="AS_Supplier">顧客群組</label>
            </div>
            <AreaLoading v-else></AreaLoading>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                  <div
                    class="form-floating mt-3"
                    v-if="( advancedSearchData.singleSelect.searchByContent === '顧客 ID' ||
                            advancedSearchData.singleSelect.searchByContent === '顧客名稱' ||
                            advancedSearchData.singleSelect.searchByContent === '顧客暱稱' ||
                            advancedSearchData.singleSelect.searchByContent === '顧客 FB 名稱' ||
                            advancedSearchData.singleSelect.searchByContent === '顧客 Line 名稱' 
                          ) && data.id === 'searchByContent'"
                  >
                    <input
                      type="text"
                      class="form-control"
                      :id="`SearchText${data.id}`"
                      maxlength="100"
                      placeholder="輸入內容"
                      v-model="advancedSearchData.searchContentText"
                    />
                    <label :for="`SearchText${data.id}`">輸入內容(100字)</label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// mixin
import { HandleImages } from '../../../methods/mixins/handleImages'
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// components
import BatchCheckOut from '../../../components/modal/BatchCheckOut.vue'
import FieldFilter from '../../../components/tools/FieldFilter.vue'
// methods
import { SplitGraph } from "../../../methods/mixins/splitGraph"

export default {
  components: {DataTable, Column, ColumnGroup, Row, BatchCheckOut, FieldFilter},
  data() {
    return {
      // modal
      virtualCustomerModal: {},
      mergeParticipantModal: {},
      editNickNameModal: {},
      editNameModal: {},
      editCustomerGroupModal: {},
      setVipModal: {},
      setForbiddenModal: {},
      verifyModal: {},
      updateWalletModal: {},
      batchSetVipModal: {},
      batchSetCustomerGroupModal: {},
      exportExcelModal: {},
      delModal: {},
      restoreModal: {},
      notifyModal: {},
      introductionModal: {},
      mergeParticipantForFbSameNameModal: {},
      advancedSearchModal: {},
      //data
      serverToken: '',
      storeId: 0,
      // 開啟批次結單
      showBatchCheckOutModal: false,
      name: '',
      selectVip: 0,
      selectCustomerGroupId: 0,
      updateWalletData: {
        adjustment: 0,
        note: ''
      },
      batchOperate: {
        before: '',
        after: ''
      },
      participantsData: [],
      serchPhysicalUserData: [],
      mergePhysicalUserSearch: null,
      mergePhysicalUserChoose: null,
      // 發送 notify
      notifyMsg: '',
      notifyStatus: '',
      checkIsSetOwnNotifyMsg: false,
      notifyOtherData: null, // 用來記錄訂單愈帳單詳細資訊
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      tableAreaLoading: false,
      participantList: [],
      participantContainMergeInfo: null,
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 選擇到的物件
      selectItems: [],
      /** 表格搜尋值 */
      tableSearchValue: '',
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'id',
        data: []
      },
      // 操作方式(批次:true、單選:false)
      isBatch: false,
      // 賣場資訊
      storeInfo: {},
      // storeVips
      storeVips: {
        loading: false,
        data: []
      },
      // 顧客群組資訊
      customerGroups: {
        loading: false,
        data: []
      },
      // 合併顧客狀態
      mergeParticipantStatus: 'p_merge_v',
      // 一件合併 FB 虛擬顧客
      sameFbNameParticipants: [],
      loadingText: null,
      // 進階搜尋
      advancedSearchData: {
        searchContentText: '',
        billTimeRange: {
          switch: false,
          startTime: null,
          endTime: null
        },
        showBillMoney: false, // 2.0 顯示帳單區間金額，用 getSiteObjects 另外取得
        orderTimeRange: {
          switch: false,
          startTime: null,
          endTime: null
        },
        customerGroup: null,
        singleSelect: {
          participantStatus: '審核通過且未被刪除',
          allocatedAndCheckout: '不選擇',
          uncheckoutStatus: '不選擇',
          walletStatus: '不選擇',
          vipStatus: '不選擇',
          participantHabitA: '不選擇',
          participantHabitB: '不選擇',
          searchByContent: '不選擇',
          mergeStatus: '顯示全部',
          arrivalStatus: '不選擇',
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  mixins: [HandleImages, SplitGraph],
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
  },
  mounted() {
    this.createModals([
      'editNickNameModal','editNameModal','editCustomerGroupModal','setVipModal','setForbiddenModal','verifyModal'
      ,'updateWalletModal','virtualCustomerModal','exportExcelModal','delModal','restoreModal','batchSetVipModal'
      ,'batchSetCustomerGroupModal','mergeParticipantModal','notifyModal','introductionModal',
      'advancedSearchModal', 'mergeParticipantForFbSameNameModal',
    ])
    this.changeTableHeigth()
    // 監聽視窗異動
    window.addEventListener("resize", this.changeTableHeigth)
  },
  unmounted() {
    // 移除監聽視窗異動
    window.removeEventListener("resize", this.changeTableHeigth)
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-participant-local')
  },
  watch: {
    mergePhysicalUserSearch: {
      handler(val) {
        this.serchPhysicalUserData = this.participantsData
        if (val !== '') {
          this.serchPhysicalUserData = this.serchPhysicalUserData.filter(item => {
            const mergeStr = `${item.id},${item.user.name},${item.nickName}`
            return mergeStr.match(val)
          })
        }
      }
    }
  },
  computed: {
    // 偏好 > 表格預設顯示筆數
    D4Row() {
      let D4Row = 20
      this.$store.state.Prefer.p_participant.rowCount.forEach(item => {
        if (item.selected) D4Row = item.value
      })
      return parseInt(D4Row)
    },
    // 偏好 > 進階搜尋預設時間區間
    timeRange() {
      return parseInt(this.$store.state.Prefer.p_participant.searchTimeRange[0].value)
    },
    // 偏好 > 表格欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_participant.fieldFilter
    },
    // 偏好 > 是否顯示 "一鍵合併 FB 虛擬顧客" 功能
    mergeFbExtensionVirtualUser() {
      let show = false;
      this.$store.state.Prefer.p_participant.mergeFbExtensionVirtualUser.forEach(item => {
        if (item.selected) show = item.value === "show" ? true : false;
      })
      return show;
    },
    // 偏好 > 一鍵合併虛擬顧客一次合併數量
    mergeFbExtensionVirtualUserCount() {
      return parseInt(this.$store.state.Prefer.p_participant.mergeFbExtensionVirtualUserCount[0].value)
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 介紹表格可點選區寬度
      this.scrollWidth = `max-width: ${window.innerWidth - 20}px`
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-participant-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'participantStatus',
          key: '顧客狀態',
          value: [
            {
              id: 'A1',
              value: '審核通過且未被刪除'
            },
            {
              id: 'A2',
              value: '已刪除'
            },
            {
              id: 'A3',
              value: '審核未通過'
            },
            {
              id: 'A4',
              value: '尚未審核'
            },
            {
              id: 'A5',
              value: '所有'
            },
          ]
        },
        {
          id: 'allocatedAndCheckout',
          key: '配單/結單',
          value: [
            {
              id: 'B1',
              value: '不選擇'
            },
            {
              id: 'B2',
              value: '已完成配單與結單'
            },
            {
              id: 'B3',
              value: '未配單 > 0 且 已配未結 > 0'
            },
            {
              id: 'B4',
              value: '未配單 > 0'
            },
            {
              id: 'B5',
              value: '已配未結 > 0'
            },
          ]
        },
        {
          id: 'uncheckoutStatus',
          key: '未結單金額狀態',
          value: [
            {
              id: 'C1',
              value: '不選擇'
            },
            {
              id: 'C2',
              value: '未結單金額 > 0'
            }
          ]
        },
        {
          id: 'walletStatus',
          key: '購物金餘額',
          value: [
            {
              id: 'D1',
              value: '不選擇'
            },
            {
              id: 'D2',
              value: '> 0'
            },
            {
              id: 'D3',
              value: '= 0'
            }
          ]
        },
        {
          id: 'vipStatus',
          key: '顧客 VIP',
          value: [
            {
              id: 'E1',
              value: '不選擇'
            },
            {
              id: 'E2',
              value: '是 VIP'
            },
            {
              id: 'E2',
              value: '非 VIP'
            }
          ]
        },
        {
          id: 'participantHabitA',
          key: '顧客習性 1',
          value: [
            {
              id: 'F1',
              value: '不選擇'
            },
            {
              id: 'F2',
              value: '全部到貨但還尚有未結單的顧客'
            }
          ]
        },
        {
          id: 'participantHabitB',
          key: '顧客習性 2',
          value: [
            {
              id: 'G1',
              value: '不選擇'
            },
            {
              id: 'G2',
              value: '購物金不夠支付未結單的金額'
            }
          ]
        },
        {
          id: 'mergeStatus',
          key: '合併狀態',
          value: [
            {
              id: 'I1',
              value: '顯示全部'
            },
            {
              id: 'I2',
              value: '僅顯示主帳號'
            },
            {
              id: 'I3',
              value: '僅顯示被合併帳號'
            }
          ]
        },
        {
          id: 'arrivalStatus',
          key: '到貨狀態',
          value: [
            {
              id: 'J1',
              value: '不選擇'
            },
            {
              id: 'J2',
              value: '全到貨'
            }
          ]
        },
        {
          id: 'searchByContent',
          key: '依內容搜尋',
          value: [
            {
              id: 'H1',
              value: '不選擇'
            },
            {
              id: 'H2',
              value: '顧客 ID'
            },
            {
              id: 'H3',
              value: '顧客名稱'
            },
            {
              id: 'H4',
              value: '顧客暱稱'
            },
            {
              id: 'H5',
              value: '顧客 FB 名稱'
            },
            {
              id: 'H6',
              value: '顧客 Line 名稱'
            }
          ]
        },
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // dataTable 內容高度計算
    changeTableHeigth() {
      // 紀錄 dataTabel 高度 (40px: 最上面 marginTop, 170px: 表格中除了內容其餘多的)
      this.scrollHeight = window.innerHeight - document.getElementById('ParticipantTopArea').clientHeight - 160
    },
    // 開啟 modal
    showModal(status, item) {
      this.isBatch = false
      if (status === 'editNickName') {
        this.getSelectInfo(item)
        this.name = item.nickName
        this.editNickNameModal.show()
      } else if (status === 'editName') {
        this.getSelectInfo(item)
        this.name = item.user.name
        this.editNameModal.show()
      } else if (status === 'editCustomerGroup') {
        this.getSelectInfo(item)
        this.selectCustomerGroupId = item.customerGroup.id ? item.customerGroup.id : 0
        this.editCustomerGroupModal.show()
      } else if (status === 'setVip') {
        // 批次
        if(!item) {
          this.isBatch = true
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
          this.propsSelectInfos.data = this.selectItems
          this.selectVip = 0
        } 
        // 單項
        else {
          this.getSelectInfo(item)
          this.selectVip = item.storeVip.id ? item.storeVip.id : 0
        }
        this.setVipModal.show()
      } else if (status === 'setForbidden') {
        this.getSelectInfo(item)
        this.setForbiddenModal.show()
      } else if (status === 'mergeParticipant') {
        // 合併顧客
        // 紀錄合併狀態
        this.mergeParticipantStatus = item
        // 判斷是否有誤
        const errorMessage = this.handleMergeParticipantError()
        if (errorMessage) return this.SweetAlert('other', errorMessage)
        // 依照合併狀態去過濾被合併顧客列表
        this.filterParticipants()
        this.serchPhysicalUserData = this.participantsData
        this.mergePhysicalUserSearch = ''
        this.mergePhysicalUserChoose = null
        this.mergeParticipantModal.show()
      } else if (status === 'virtualCustomer') {
        this.name = ''
        this.virtualCustomerModal.show()
      } else if (status === 'verify') {
        // 批次
        if(!item) {
          this.isBatch = true
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
          this.propsSelectInfos.data = this.selectItems
        }
        // 單項
        else {
          this.getSelectInfo(item)
        }
        // 判斷是否選到已審核通過的顧客
        let isVerify = false
        this.selectItems.some(item => {
          if (item.verify === true) return isVerify = true
        })
        if (isVerify) return this.SweetAlert('other', '有選到已審核通過的顧客')
        this.verifyModal.show()
      } else if (status === 'updateWallet') {
        this.updateWalletData = {
          adjustment: 0,
          note: ''
        }
        // 批次
        if(!item) {
          this.isBatch = true
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
          this.propsSelectInfos.data = this.selectItems
        }
        // 單項
        else {
          this.getSelectInfo(item)
        }
        this.updateWalletModal.show()
      } else if (status === 'batchCheckOut') {
        // 批次結單
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        // 傳遞到子層開啟 modal
        this.showBatchCheckOutModal = true
      } else if (status === 'batchSetVip') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        this.batchOperate = {
          before: '',
          after: ''
        }
        this.batchSetVipModal.show()
      } else if (status === 'batchSetCustomerGroup') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        this.batchOperate = {
          before: '',
          after: ''
        }
        this.batchSetCustomerGroupModal.show()
      } else if (status === 'exportExcel') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        this.exportExcelModal.show()
      } else if (status === 'del') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        // 判斷是否選到已被刪除的顧客
        let isDeleted = false
        this.selectItems.some(item => {
          if (item.deleted) {
            isDeleted = true
            return true
          }
        })
        if (isDeleted) return this.SweetAlert('other', '有選到已被刪除的顧客')
        this.delModal.show()
      } else if (status === 'restore') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        // 判斷是否選到未被刪除的顧客
        let isRestore = false
        this.selectItems.some(item => {
          if (!item.deleted) return isRestore = true
        })
        if (isRestore) return this.SweetAlert('other', '有選到未被刪除的顧客')
        this.restoreModal.show()
      } else if (status === 'lineNotifyGeneral') {
        // 一般
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.selectItems = this.selectItems.filter(item => {
          return item.verify && !item.deleted && item.line.bindingLineNotify
        })
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請選擇<br><span class="fw-bolder text-danger">有綁定Line Notify</span> 且 <span class="fw-bolder text-danger">未被刪除</span> 且 <span class="fw-bolder text-danger">審核通過</span><br>的顧客')
        this.propsSelectInfos.data = this.selectItems
        this.notifyMsg = ''
        this.notifyStatus = 'general'
        this.checkIsSetOwnNotifyMsg = true
        document.querySelector("input[type=file]").value = ''
        this.notifyModal.show()
      } else if (status === 'lineNotifyGroupA') {
        // 群發:已配單但未結單
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.selectItems = this.selectItems.filter(item => {
          return item.allocatedButUnCheckOutQuantity > 0 && item.verify && !item.deleted && item.line.bindingLineNotify
        })
        if (this.selectItems.length === 0) return this.SweetAlert('other', '您所選的顧客皆可能"未符合已配未結條件"，也可能顧客本身"尚未通過審核"或是"已被刪除"或是"未綁定 Line Notify"')
        this.propsSelectInfos.data = this.selectItems
        this.notifyStatus = 'groupA'
        this.$methods.switchLoading('show')
        this.getNotitfyOtherInfo().then(res => {
          console.log(res)
          if (res.code === "200") {
            const merchorders = res.data[0].objects
            this.notifyOtherData = null
            this.notifyOtherData = new Map()
            merchorders.forEach(item => { this.sortoutNotifyOtherData(item) })
            console.log(this.notifyOtherData)
            this.notifyMsg = ''
            this.checkIsSetOwnNotifyMsg = false
            document.querySelector("input[type=file]").value = ''
            this.notifyModal.show()
          }
          this.$methods.switchLoading('hide')
        }).catch(err => {
          console.log(err)
          this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.$methods.switchLoading('hide')
        })
      } else if (status === 'lineNotifyGroupB') {
        // 群發:已結單但未付款
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.selectItems = this.selectItems.filter(item => {
          return item.unPaidQuantity > 0 && item.verify && !item.deleted && item.line.bindingLineNotify
        })
        if (this.selectItems.length === 0) return this.SweetAlert('other', '您所選的顧客皆可能"未符合已結單但未付款"，也可能顧客本身"尚未通過審核"或是"已被刪除"或是"未綁定 Line Notify"')
        this.propsSelectInfos.data = this.selectItems
        this.$methods.switchLoading('show')
        this.notifyStatus = 'groupB'
        this.getNotitfyOtherInfo().then(res => {
          console.log(res)
          if (res.code === "200") {
            const bills = res.data[0].objects
            this.notifyOtherData = null
            this.notifyOtherData = new Map()
            let merchorders = []
            bills.forEach(bill => {
              bill.billBodies.forEach(body => {
                body.merchOrder.buyerId = bill.buyerId
                merchorders.push(body.merchOrder)
              })
            })
            merchorders.forEach(item => { this.sortoutNotifyOtherData(item) })
            this.notifyMsg = ''
            this.checkIsSetOwnNotifyMsg = false
            document.querySelector("input[type=file]").value = ''
            this.notifyModal.show()
          }
          this.$methods.switchLoading('hide')
        }).catch(err => {
          console.log(err)
          this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.$methods.switchLoading('hide')
        })
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      } else if (status === 'mergeParticipantForFbSameName') {
        // 一鍵合併 (為了 FB 擴充虛擬顧客)
        this.loadingText = null;
        this.mergeParticipantForFbSameNameModal.show();
        this.getMergeFbVirtualUserData();
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      this.storeInfo = {}
      // storeVips
      this.storeVips = {
        loading: true,
        data: []
      }
      // 顧客群組資訊
      this.customerGroups = {
        loading: true,
        data: []
      }
      this.$api.store.getOtherInfo([this.storeId], "{getStoreVips,getCustomerGroups}").then(res => {
        console.log(res)
        if (res.code === '200') {
          const storeInfo = res.data[0].objects[0]
          this.storeInfo = storeInfo
          // storeVips
          this.storeVips = {
            loading: false,
            data: storeInfo.storeVips
          }
          // 顧客群組資訊
          this.customerGroups = {
            loading: false,
            data: storeInfo.customerGroups
          }
          // 先取得預設時間區間
          this.computedD4TimeRange()
          this.getStoreParticipantContainMergeInfo('advancedSearch')
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // * 更新資料 (start)
    /**
     * OK 更新資料
     * @updateItem 要更新的物件
     * @modal 需要關閉的 modal
     */
    updateData(updateItem, modal) {
      this.$methods.switchLoading('show')
      let searchData = {
        storeId: this.storeId,
        ids: []
      }
      this.selectItems.forEach(participant => { searchData.ids.push(participant.id) })
      // 取要更新的資料
      this.$api.participant.getDataTable(searchData).then(res => {
        console.log(res);
        if (res.code === '200') {
          const participants = res.data
          this.sortoutUpdateData(participants, updateItem, modal)
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 整理更新資料
    sortoutUpdateData(participants, updateItem, modal) {
      let updateObj
      this.participantList.forEach((origin, originIndex, originArr) => {
        participants.forEach((update_item) => {
          const update = this.handleParticipants(update_item)
          if (origin.id === update.id) {
            switch (updateItem) {
              case 'normal':
                originArr[originIndex] = JSON.parse(JSON.stringify(update))
                break;
              default:
                throw new Error('Participants.vue > sortoutUpdateData > updateItem 未處理')
            }
          }
        })
      })

      // 新增功能
      switch (modal) {
        case 'virtualCustomerModal':
          // 新增虛擬顧客
          updateObj = this.handleParticipants(participants[0])
          this.participantList.push(JSON.parse(JSON.stringify(updateObj)))
          break;
      }

      if (modal === 'mergeParticipantModal') this.handleMergeParticipants() // 合併帳號要刪除被合併的顧客
      if (modal !== "showBatchCheckOutModal") this.SweetAlert('200')
      if (modal !== "showBatchCheckOutModal") {
        this[modal].hide()
      }
      this.selectItems = []
      this.$methods.switchLoading('hide')
    },
    // OK 合併帳號 > 更新處理
    handleMergeParticipants() {
      if (this.mergeParticipantStatus === 'p_merge_v' || this.mergeParticipantStatus === 'v_merge_v') {
        // 刪除被合併帳號
        this.participantList.some((item, index) => {
          if (item.id === this.mergePhysicalUserChoose.id) {
            this.participantList.splice(index, 1)
            return true
          }
        })
      }
    },
    // * 更新資料 (end)

    // * 取顧客 (start)
    // OK 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.billTimeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.billTimeRange.startTime = this.$methods.moment().subtract(this.timeRange, 'days').format('YYYY-MM-DD')
      this.advancedSearchData.orderTimeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.orderTimeRange.startTime = this.$methods.moment().subtract(this.timeRange, 'days').format('YYYY-MM-DD')
    },
    // OK 常用搜尋
    frequentlySearched(searchStatus) {
      // 紀錄要變更的單選項目
      let singleSelectOption = null
      // 還原所有單選選項預設值
      this.advancedSearchData.singleSelect.participantStatus = '審核通過且未被刪除'
      this.advancedSearchData.singleSelect.allocatedAndCheckout = '不選擇'
      this.advancedSearchData.singleSelect.arrivalStatus = '不選擇'
      // 判斷是哪個單選項目要變更
      if (searchStatus === '已刪除' || searchStatus === '審核未通過' || searchStatus === '尚未審核') singleSelectOption = 'participantStatus'
      else if (searchStatus === '已配未結 > 0') singleSelectOption = 'allocatedAndCheckout'
      else if (searchStatus === '全到貨') singleSelectOption = 'arrivalStatus'
      // 判斷是否有未處理到的項目，提醒工程師用
      if (!singleSelectOption) return alert('沒有此搜尋選項')
      this.advancedSearchData.singleSelect[singleSelectOption] = searchStatus
      this.advancedSearch()
    },
    // OK 取賣場顧客所有有關合併帳號資料
    getStoreParticipantContainMergeInfo(status, otherInfo) {
      this.participantContainMergeInfo = new Map()
      this.$api.participant.getStoreParticipantContainMergeInfo({storeId: this.storeId}).then(res => {
        console.log(res)
        if (res.code === "200") {
          const participantContainMergeInfo = res.data
          // 紀錄使用者綁定社群資料
          participantContainMergeInfo.forEach(item => {
            this.participantContainMergeInfo.set(item.participantId, {
              name: item.name,
              physicalUserId: item.physicalUserId,
              mergePhysicaluserId: item.mergePhysicaluserId,
              isMaster: item.isMaster,
              afterMergeFbName: item.afterMergeFbName ? item.afterMergeFbName : null,
              afterMergeFbPicture: item.afterMergeFbPicture ? item.afterMergeFbPicture : null,
              afterMergeLineName: item.afterMergeLineName ? item.afterMergeLineName : null,
              afterMergeLinePicture: item.afterMergeLinePicture ? item.afterMergeLinePicture : null,
              afterMergeBindingLineNotify: item.bindingLineNotify ? item.bindingLineNotify : null
            })
          })
          // status: advancedSearch(接著做進階搜尋), updateData(接著做更新資料)
          if (status === "advancedSearch") this.advancedSearch()
          else if (status === "updateData") this.updateData(otherInfo.updateItem, otherInfo.modal)
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 進階搜尋
    advancedSearch(closeModal) {
      if (this.advancedSearchData.singleSelect.searchByContent !== '不選擇' && !this.advancedSearchData.searchContentText) return this.SweetAlert('other', '請輸入搜尋內容')
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.selectItems = []
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      if (this.recordAdvancedSearchData.showBillMoney) this.tableAreaLoading = true
      let searchData = {
        storeId: this.storeId
      }
      // 進階搜尋 > 帳單區間金額(有付款)
      if (this.advancedSearchData.billTimeRange.switch) {
        if (this.advancedSearchData.billTimeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.billTimeRange.startTime} 00:00:00`) / 1000
          searchData.moneyRangeBillStartTime = startTime
        }
        if (this.advancedSearchData.billTimeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.billTimeRange.endTime} 23:59:59`) / 1000
          searchData.moneyRangeBillEndTime = endTime
        }
      }
      // 進階搜尋 > 訂單區間金額
      if (this.advancedSearchData.orderTimeRange.switch) {
        if (this.advancedSearchData.orderTimeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.orderTimeRange.startTime} 00:00:00`) / 1000
          searchData.moneyRangeOrderStartTime = startTime
        }
        if (this.advancedSearchData.orderTimeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.orderTimeRange.endTime} 23:59:59`) / 1000
          searchData.moneyRangeOrderEndTime = endTime
        }
      }
      // 進階搜尋 > 顧客群組
      if (this.advancedSearchData.customerGroup) {
        searchData.customerGroupId = this.advancedSearchData.customerGroup.id
      }
      // 進階搜尋 > 顧客狀態
      switch(this.advancedSearchData.singleSelect.participantStatus) {
        case '審核通過且未被刪除':
          searchData.participantStatus = "approvedAndNotDeleted"
          break;
        case '已刪除':
          searchData.participantStatus = "deleted"
          break;
        case '審核未通過':
          searchData.participantStatus = "auditFailed"
          break;
        case '尚未審核':
          searchData.participantStatus = "notYetReviewed"
          break;
      }
      // 進階搜尋 > 配單/結單
      switch(this.advancedSearchData.singleSelect.allocatedAndCheckout) {
        case '已完成配單與結單':
          searchData.allocatedAndCheckout = "bothAreCompleted"
          break;
        case '未配單 > 0 且 已配未結 > 0':
          searchData.allocatedAndCheckout = "bothAreGreaterThanZero"
          break;
        case '未配單 > 0':
          searchData.allocatedAndCheckout = "unAllocatedGreaterThanZero"
          break;
        case '已配未結 > 0':
          searchData.allocatedAndCheckout = "allocatedAndunCheckOutGreaterThanZero"
          break;
      }
      // 進階搜尋 > 未結單金額狀態
      switch (this.advancedSearchData.singleSelect.uncheckoutStatus) {
        case '未結單金額 > 0':
          searchData.uncheckoutStatus = "greaterThanZero"
          break;
      }
      // 進階搜尋 > 購物金餘額
      switch (this.advancedSearchData.singleSelect.walletStatus) {
        case '> 0':
          searchData.walletStatus = "greaterThanZero"
          break;
        case '= 0':
          searchData.walletStatus = "equalToZero"
          break;
      }
      // 進階搜尋 > 顧客 VIP
      switch (this.advancedSearchData.singleSelect.vipStatus) {
        case '是 VIP':
          searchData.vipStatus = "isVip"
          break;
        case '非 VIP':
          searchData.vipStatus = "nonVip"
          break;
      }
      // 進階搜尋 > 顧客習性 1
      switch (this.advancedSearchData.singleSelect.participantHabitA) {
        case '全部到貨但還尚有未結單的顧客':
          searchData.participantHabitA = "fullArrivalButStillUnCheckOut"
          break;
      }
      // 進階搜尋 > 顧客習性 2
      switch (this.advancedSearchData.singleSelect.participantHabitB) {
        case '購物金不夠支付未結單的金額':
          searchData.participantHabitB = "walletIsNotEnoughToPay"
          break;
      }
      // 進階搜尋 > 合併狀態
      switch (this.advancedSearchData.singleSelect.mergeStatus) {
        case '僅顯示主帳號':
          searchData.mergeStatus = "onlyShowMaster"
          break;
        case '僅顯示被合併帳號':
          searchData.mergeStatus = "onlyShowSlave"
          break;
      }
      // 進階搜尋 > 到貨狀態
      switch (this.advancedSearchData.singleSelect.arrivalStatus) {
        case '全到貨':
          searchData.arrivalStatus = "fullArrival"
          break;
      }
      // 進階搜尋 > 依內容搜尋
      switch(this.advancedSearchData.singleSelect.searchByContent) {
        case '顧客 ID':
          searchData.ids = this.advancedSearchData.searchContentText.split(",")
          break;
        case '顧客名稱':
          searchData.name = this.advancedSearchData.searchContentText
          break;
        case '顧客暱稱':
          searchData.nickName = this.advancedSearchData.searchContentText
          break;
      }

      // 取顧客管理資料
      this.$api.participant.getDataTable(searchData).then(res => {
        console.log(res)
        if (res.code === '200') {
          const participantList = res.data
          this.participantList = []
          this.sortoutParticipants(participantList)
        }
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
        this.dataTabelLoading = false
        this.tableAreaLoading = false
      })
      if (closeModal) this.advancedSearchModal.hide()
    },
    // 整理顧客
    sortoutParticipants(participantList) {
      let participantIds = []
      participantList.forEach(item => {
        participantIds.push(item.participantId)
        const object = this.handleParticipants(item)
        // 進階搜尋 > 依內容搜尋 > 顧客 FB 名稱、顧客 Line 名稱 (只有 line 跟 fb 因為有合併顧客問題)
        let matchBuyerStatus = false
        switch (this.advancedSearchData.singleSelect.searchByContent) {
          case '顧客 FB 名稱':
            if (object.fb.name && object.fb.name.match(this.advancedSearchData.searchContentText)) matchBuyerStatus = true
            break;
          case '顧客 Line 名稱':
            if (object.line.name && object.line.name.match(this.advancedSearchData.searchContentText)) matchBuyerStatus = true
            break;
          default:
            matchBuyerStatus = true
            break;
        }

        if (matchBuyerStatus) this.participantList.push(JSON.parse(JSON.stringify(object)))
      })
      console.log(this.participantList)
      this.$methods.switchLoading('hide')
      this.dataTabelLoading = false
      if (participantIds.length === 0 || !this.advancedSearchData.showBillMoney) return  this.tableAreaLoading = false
      // 取得帳單區間金額
      this.getBillMoney(participantIds)
    },
    // 特別處理顧客物件
    handleParticipants(item) {
      const object = {
        id: item.participantId,
        verify: !item.verify && item.verify !== false ? null : item.verify,
        forbidden: item.forbidden,
        deleted: item.deleted,
        nickName: item.nickName ? item.nickName : null,
        userType: item.userType,
        wallet: item.wallet,
        unAllocatedQuantity: item.unAllocatedQuantity,
        allocatedButUnCheckOutQuantity: Math.max(item.allocatedButUnCheckOutQuantity, 0),
        unPaidQuantity: item.unPaidQuantity,
        unShippedQuantity: item.unShippedQuantity,
        unCheckOutMoney: item.unCheckOutMoney ? item.unCheckOutMoney : 0,
        unCheckOutQuantity: item.unCheckOutQuantity ? item.unCheckOutQuantity : 0,
        moneyRangeOrder: item.moneyRangeOrder ? item.moneyRangeOrder : 0,
        moneyRangeBill: 0, // 另外取得
        allocatedButUnCheckOutQuantityMerchOrderIds: item.allocatedButUnCheckOutQuantityMerchOrderIds ? item.allocatedButUnCheckOutQuantityMerchOrderIds.split(',') : [],
        unPaidBillIds: item.unPaidBillIds ? item.unPaidBillIds.split(',') : [],
        lastLogin: this.$methods.moment(item.lastLogin).format("YYYY-MM-DD HH:mm:ss"),
        verifyDate: this.$methods.moment(item.verifyDate).format("YYYY-MM-DD HH:mm:ss"),
        fb: {
          name: item.fbName ? item.fbName : null,
          profilePicture: item.fbProfilePicture ? item.fbProfilePicture : null,
          bindingFb: item.bindingFb ? true : false,
          personId: item.fbPersonId ? item.fbPersonId : null,
        },
        line: {
          name: item.lineName ? item.lineName : null,
          profilePicture: item.lineProfilePicture ? item.lineProfilePicture : null,
          bindingLine: item.bindingLine ? true : false,
          bindingLineNotify: item.bindingLineNotify ? true : false
        },
        user: {
          name: item.userName,
          masterId: item.masterId ? item.masterId : null,
          slaveId: item.slaveId ? item.slaveId : null,
        },
        storeVip: {
          id: item.storeVipId ? item.storeVipId : null,
          name: item.storeVipName ? item.storeVipName : null,
          level: item.storeVipLevel ? item.storeVipLevel : null
        },
        customerGroup: {
          id: item.customerGroupId ? item.customerGroupId : null,
          name: item.customerGroupName ? item.customerGroupName : null
        }
      }

      // 額外補上合併帳號資訊
      let mergeInfo = this.participantContainMergeInfo.get(object.id)
      if (mergeInfo) {
        try {
          object.fb = {
            name: object.fb.name ? object.fb.name : mergeInfo.afterMergeFbName,
            profilePicture: object.fb.profilePicture ? object.fb.profilePicture : mergeInfo.afterMergeFbPicture,
            bindingFb: object.fb.name ? (object.fb.bindingFb ? true : false) : (mergeInfo.afterMergeFbName ? true : false),
            personId: object.fb.personId,
          }
          object.line = {
            bindingLineNotify: object.line.name ? object.line.bindingLineNotify : mergeInfo.afterMergeBindingLineNotify, // 這個要擺在最前面，因為它會先判斷 lineName
            name: object.line.name ? object.line.name : mergeInfo.afterMergeLineName,
            profilePicture: object.line.profilePicture ? object.line.profilePicture : mergeInfo.afterMergeLinePicture,
            bindingLine: object.line.name ? (object.line.bindingLine ? true : false) : (mergeInfo.afterMergeLineName ? true : false),
          }
        } catch (error) {
          throw new Error(error)
        }
      }

      if (object.id === 10679) console.log(object)

      return object
    },
    // 取顧客帳單 query
    getBillMoney(participantIds) {
      let billQuery = {"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":27},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"type":3,"value":participantIds}},{"column":{"columnName":"parentId","siteObjectType":27,},"operator":{"operator":"true","type":4,},},{"column":{"columnName":"paid","siteObjectType":27,},"operator":{"operator":"=","type":1,"value":true},}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
      // 帳單區間
      if (this.advancedSearchData.billTimeRange.switch) {
        if (this.advancedSearchData.billTimeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.billTimeRange.startTime} 00:00:00`) / 1000
          billQuery.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 27
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.billTimeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.billTimeRange.endTime} 23:59:59`) / 1000
          billQuery.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 27
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      this.getBills(billQuery)
    },
    // 取顧客帳單
    getBills(billQuery) {
      const vm = this
      const getOrdersAndBillsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      this.tableAreaLoading = true
      const data = [
        {
          query: billQuery,
          methods: '{getTotalPrice}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getOrdersAndBillsApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const bills = res.data[0].objects
            vm.sortOutBills(bills)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理顧客帳單區間金額資料
    sortOutBills(bills) {
      setTimeout(() => {
        let participantsMap = new Map() // 紀錄相同顧客
        // 帳單
        bills.forEach(item => {
          if (!participantsMap.has(item.buyerId)) {
            participantsMap.set(item.buyerId, {
              moneyRangeBill: 0
            })
          }
          if (item.paid) {
            participantsMap.get(item.buyerId).moneyRangeBill += item.totalPrice // 包含 "單身總額" "帳單平衡總額" "運費"
            if (item.walletUsed) participantsMap.get(item.buyerId).moneyRangeBill -= item.walletUseAmount
          }
        })
        console.log(participantsMap)

        this.participantList.forEach(item => {
          item.moneyRangeBill = participantsMap.get(item.id) ? participantsMap.get(item.id).moneyRangeBill : 0
        })
        this.tableAreaLoading = false
      }, 1)
    },
    // * 取顧客 (end)

    // 準備 modal 內選擇幾筆資訊
    getSelectInfo(item) {
      this.selectItems = []
      this.propsSelectInfos.data = []
      this.selectItems.push(item)
      this.propsSelectInfos.data = this.selectItems
      console.log('選擇欄位:',this.selectItems)
    },
    // OK 編輯名稱 
    editName() {
      this.$methods.switchLoading('show')
      const data = new FormData()
      data.append('participantId', this.selectItems[0].id)
      data.append('name', this.name)
      this.$api.participant.updateVirtualUserName(data).then(res => {
        console.log(res);
        if (res.code === '200') {
          // 更新資料
          this.updateData('normal', 'editNameModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 編輯暱稱
    editNickName() {
      this.$methods.switchLoading('show')
      const data = []
      let obj = {
        id: this.selectItems[0].id,
        attributes: {
          setNickName: this.name
        }
      }
      data.push(obj)
      this.$api.participant.setAttribute(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          // 更新資料
          this.updateData('normal', 'editNickNameModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 設定顧客群組
    setCustomerGroup() {
      this.$methods.switchLoading('show')
      const data = []
      let obj = {
        id: this.selectItems[0].id,
        attributes: {
          setCustomerGroupId: this.selectCustomerGroupId  !== 0 ? this.selectCustomerGroupId : null
        }
      }
      data.push(obj)
      this.$api.participant.setAttribute(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          // 更新資料
          this.updateData('normal', 'editCustomerGroupModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 設定 VIP 等級
    setVip() {
      this.$methods.switchLoading('show')
      const data = []
      this.selectItems.forEach(item => {
        let obj = {
          id: item.id,
          attributes: {
            setStoreVipId: this.selectVip  !== 0 ? this.selectVip : null
          }
        }
        data.push(obj)
      })
      this.$api.participant.setAttribute(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          // 更新資料
          this.updateData('normal', 'setVipModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 設定禁止/開放個人賣場
    setForbidden(status) {
      this.$methods.switchLoading('show')
      const data = []
      let obj = {
        id: this.selectItems[0].id,
        attributes: {
          setForbidden: status
        }
      }
      data.push(obj)
      this.$api.participant.setAttribute(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          // 更新資料
          this.updateData('normal', 'setForbiddenModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 設定審核
    setVerify(status) {
      this.$methods.switchLoading('show')
      const participantIds = []
      this.selectItems.forEach(item => participantIds.push(item.id))
      const data = new FormData()
      data.append('participantIds', participantIds)
      data.append('verify', status)
      this.$api.participant.setVerify(data).then(res => {
        console.log(res);
        if (res.code === '200') {
          // 更新資料
          this.updateData('normal', 'verifyModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 調整購物金
    updateWallet() {
      if (this.updateWalletData.adjustment === '') return this.SweetAlert('other', '調整金額不為空值')
      if (this.updateWalletData.adjustment === 0) return this.SweetAlert('other', '調整金額不為零')
      this.$methods.switchLoading('show')
      const data = []
      this.selectItems.forEach(item => {
        const obj = {
          id: item.id,
          adjustment: this.updateWalletData.adjustment,
          note: this.updateWalletData.note
        }
        data.push(obj)
      })
      this.$api.participant.updateWallet(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          // 更新資料
          this.updateData('normal', 'updateWalletModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 新增虛擬顧客
    createVirtualUser() {
      this.$methods.switchLoading('show')
      const data = new FormData()
      data.append('storeId', this.storeId)
      data.append('name', this.name)
      this.$api.participant.createVirtualUser(data).then(res => {
        console.log(res);
        if (res.code === '200') {
          this.selectItems = [res.data]
          this.updateData('normal', 'virtualCustomerModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 匯出 Excel
    exportExcel() {
      const token = this.serverToken
      const ids = []
      this.selectItems.forEach(item => ids.push(item.id))
      const exportExportUrl = `${process.env.VUE_APP_API}/download/exportDataTableExcel?authorization=${token}&siteObjectTypeIndex=13&ids=${ids}`
      window.open(exportExportUrl, "_blank")
      this.exportExcelModal.hide()
      this.selectItems = []
    },
    // OK 刪除 / 復原顧客
    setDeleted(status) {
      this.$methods.switchLoading('show')
      const participantIds = []
      this.selectItems.forEach(item => participantIds.push(item.id))
      const data = new FormData()
      data.append('participantIds', participantIds)
      data.append('deleted', status)
      this.$api.participant.setDeleted(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          let modelName = null
          if (status) modelName = 'delModal'
          else modelName = 'restoreModal'
          // 更新資料
          this.updateData('normal', modelName)
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // OK 批次轉換 VIP 身分
    batchSetVip() {
      if (this.batchOperate.before === '') return this.SweetAlert('other', '請選擇VIP身分')
      if (this.batchOperate.after === '') return this.SweetAlert('other', '請選擇VIP等級')
      this.$methods.switchLoading('show')
      if (this.batchOperate.before === 'null') this.batchOperate.before = null
      const data = []
      this.selectItems.forEach(item => {
        if (item.storeVip.id === this.batchOperate.before) {
          let obj = {
            id: item.id,
            attributes: {
              setStoreVipId: this.batchOperate.after
            }
          }
          data.push(obj)
        }
      })
      this.$api.participant.setAttribute(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          // 更新資料
          this.updateData('normal', 'batchSetVipModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })  
    },
    // OK 批次轉換顧客群組
    batchSetCustomerGroup() {
      if (this.batchOperate.before === '' || this.batchOperate.after === '') return this.SweetAlert('other', '請選擇顧客群組')
      this.$methods.switchLoading('show')
      if (this.batchOperate.before === 'null') this.batchOperate.before = null
      const data = []
      this.selectItems.forEach(item => {
        if (item.customerGroup.id === this.batchOperate.before) {
          let obj = {
            id: item.id,
            attributes: {
              setCustomerGroupId: this.batchOperate.after
            }
          }
          data.push(obj)
        }
      })
      this.$api.participant.setAttribute(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          // 更新資料
          this.updateData('normal', 'batchSetCustomerGroupModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },

    // * 合併顧客 (start)
    // OK 處理合併顧客選擇後是否有誤
    handleMergeParticipantError() {
      let errorMessage = null
      if (this.selectItems.length === 0) errorMessage = '請選擇一位顧客'
      if (this.selectItems.length > 1 ) errorMessage = '只能選擇一位顧客'
      // userType: 1(physicalUser), 4(virtualUser)
      if (this.selectItems.length === 1) {
        if (this.mergeParticipantStatus === 'p_merge_v' && this.selectItems[0].userType !== 1) {
          // 實體併虛擬
          errorMessage = '只能選擇實體顧客'
        } else if (this.mergeParticipantStatus === 'v_merge_v' && this.selectItems[0].userType !== 4) {
          // 虛擬併虛擬
          errorMessage = '只能選擇虛擬顧客'
        } else if (this.mergeParticipantStatus === 's_merge_s') {
          // 社群併社群
          if (this.selectItems[0].userType !== 1) {
            errorMessage = '只能選擇實體顧客'
          } else {
            const possibleToMerge = !this.selectItems[0].user.masterId && !this.selectItems[0].user.slaveId
            if (!possibleToMerge) errorMessage = '您選擇的顧客已合併過'
            else {
              if (this.selectItems[0].fb.bindingFb && this.selectItems[0].line.bindingLine) errorMessage = '只能選擇 "只綁定 FB" 或 "只綁定 Line" 的實體顧客'
              if (!this.selectItems[0].fb.bindingFb && !this.selectItems[0].line.bindingLine) errorMessage = '請選擇 "只綁定 FB" 或 "只綁定 Line" 的實體顧客'
            }
          }
        }
      }
      return errorMessage
    },
    // OK 過濾被合併顧客列表
    filterParticipants() {
      this.participantsData = this.participantList.filter(item => {
        // 先撇除已選到的顧客
        if (item.id !== this.selectItems[0].id) {
          // 在依照合併狀態過濾
          if (this.mergeParticipantStatus === 'p_merge_v' && item.userType === 4) {
            // 實體併虛擬
            return item
          } else if (this.mergeParticipantStatus === 'v_merge_v' && item.userType === 4) {
            // 虛擬併虛擬
            return item
          } else if (this.mergeParticipantStatus === 's_merge_s' && item.userType === 1) {
            // 社群併社群
            // 如果是選到綁定 FB 的實體顧客，就只過濾綁定 Line 實體顧客
            const possibleToMerge = !item.user.masterId && !item.user.slaveId
            if (this.selectItems[0].fb.bindingFb && possibleToMerge) return !item.fb.bindingFb && item.line.bindingLine
            // 如果是選到綁定 Line 的實體顧客，就只過濾綁定 Fb 實體顧客
            if (this.selectItems[0].line.bindingLine && possibleToMerge) return item.fb.bindingFb && !item.line.bindingLine
          }
        }
      })
    },
    // OK 合併顧客(實體併虛擬，虛擬併虛擬，line 併 fb)
    mergeParticipant() {
      if (!this.mergePhysicalUserChoose) return this.SweetAlert('other', '請選擇欲合併之顧客')
      this.$methods.switchLoading('show')
      const data = new FormData()
      data.append('slaveId', this.mergePhysicalUserChoose.id)
      data.append('masterId', this.selectItems[0].id)
      this.$api.participant.mergeParticipant(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          // todo 目前合併帳號的更新第一次有機率錯誤，重整後又會正確
          this.selectItems.push(this.mergePhysicalUserChoose)
          // 如果是實體併實體 (社群)，就先取得合併顧客資訊，在更新資料
          if (this.mergeParticipantStatus === 's_merge_s') this.getStoreParticipantContainMergeInfo("updateData", {updateItem: "normal", modal: "mergeParticipantModal"})
          // 剩下的合併就直接更新資料就好
          else this.updateData('normal', 'mergeParticipantModal')
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // todo 一鍵合併 FB 虛擬顧客
    getMergeFbVirtualUserData() {
      this.$methods.switchLoading('show');
      const vm = this
      const api = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getParticipants{getUser{getUserFacebookLink}}}'
        }
      ]
      
      $.ajax({
        type: 'POST',
        async: true,
        url: api,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const data = res.data[0].objects[0];
            vm.handleMergeFbVirtualUser(data.participants);
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // todo 處理一鍵合併 FB 虛擬顧客資料
    handleMergeFbVirtualUser(participants) {
      // 先整理同 FB 名稱的顧客
      let results = new Map();
      for (let i=0; i<participants.length; i++) {
        const participant = participants[i];
        const fbName = participant.user.userFacebookLink.name?.trim();
        const userName = participant.user.name.trim();
        const userType = participant.userType;
        let sameName = null;

        if (fbName) sameName = fbName;
        else if (userType === "VIRTUALUSER" && !fbName) sameName = userName;

        if (!sameName) continue;
        if (participant.deleted) continue;


        if (!results.has(fbName)) {
          results.set(fbName, {
            deleted: false, // 是否合併
            masterParticipantId: null, // 最後選擇的實體顧客
            slaveParticipantId: null, // 最後選擇的虛擬顧客
            physicalUsers: [], // 紀錄同 FB 名稱的顧客
            virtualUsers: [], // 紀錄同 FB 名稱的虛擬顧客，如果沒有 FB 紀錄，就用一般虛擬顧客名稱比較
          })
        }
        
        let object = {
          participantId: participant.id,
          deleted: participant.deleted,
          forbidden: participant.forbidden,
          verify: participant.verify,
          userType: participant.userType,
          fbName: fbName,
          userName: userName,
          personId: participant.user.userFacebookLink.personId,
          fbProfilePicture: participant.user.userFacebookLink.profilePicture,
        }
        if (participant.userType === "PHYSICALUSER") results.get(fbName).physicalUsers.push(object);
        else results.get(fbName).virtualUsers.push(object);
      }

      // 整理顯示用資料
      this.sameFbNameParticipants = [];
      this.finalSelect = [];
      for (const [key, value] of results) {
        const name = key;
        const physicalUsers = value.physicalUsers;
        const virtualUsers = value.virtualUsers;
        let masterParticipantId = value.masterParticipantId;
        let slaveParticipantId = value.slaveParticipantId;
        const deleted = value.deleted;

        if (physicalUsers.length === 0 || virtualUsers.length === 0) continue;
        if (physicalUsers.length === 1) masterParticipantId = physicalUsers[0].participantId;
        if (virtualUsers.length === 1) slaveParticipantId = virtualUsers[0].participantId;

        let object = {
          sameName: key,
          masterParticipantId,
          slaveParticipantId,
          physicalUsers,
          virtualUsers,
          deleted,
        };

        // 將有需要選擇的顧客往前排 (不是 1 對 1 的往前排)
        if (physicalUsers.length === 1 && virtualUsers.length === 1) this.sameFbNameParticipants.push(object);
        else this.sameFbNameParticipants.unshift(object);
      }
      console.log(this.sameFbNameParticipants)
      this.$methods.switchLoading('hide');
    },
    // todo 刪除一鍵合併 FB 虛擬顧客資料
    deleteMergeFbVirtualUser(index) {
      this.sameFbNameParticipants[index].deleted = true;
    },
    // todo 開始一鍵合併 FB 虛擬顧客
    async startToMergeFbVirtualUser() {
      if (this.sameFbNameParticipants.length === 0) return this.SweetAlert("other", "沒有可以合併的項目");
      this.loadingText = `目前進度: 0 / ${this.sameFbNameParticipants.length}`;
      // 每 N 筆為一個發送單位 (取決偏好 mergeFbExtensionVirtualUserCount)
      const count = this.mergeFbExtensionVirtualUserCount;
      for (let i=0; i<this.sameFbNameParticipants.length; i+=count) {
        let promises = [];
        for (let j=i; j<i+count && j<this.sameFbNameParticipants.length; j++) {
          const item = this.sameFbNameParticipants[j];
          if (item.deleted) continue;

          const masterParticipantId = item.masterParticipantId;
          const slaveParticipantId = item.slaveParticipantId;
          if (masterParticipantId && slaveParticipantId) {
            const data = new FormData();
            data.append("masterId", masterParticipantId);
            data.append("slaveId", slaveParticipantId);
            promises.push(this.$api.participant.mergeParticipant(data));
          }
        }

        await Promise.all(promises).then(responses => {
          responses.forEach(res => {
            console.log(res);
          });
        }).catch(err => {
          console.log(err);
        })

        this.loadingText = `目前進度: ${i} / ${this.sameFbNameParticipants.length}`;
      }
      this.mergeParticipantForFbSameNameModal.hide();
      this.advancedSearch();
    },
    // * 合併顧客 (end)

    // OK 發送 notify 訊息
    sendNotify() {
      if (this.checkIsSetOwnNotifyMsg && !this.notifyMsg) return this.SweetAlert('other', '請輸入傳送文字')
      this.$methods.switchLoading('show')
      // 組傳遞文字
      let message = ""
      if (!this.checkIsSetOwnNotifyMsg) {
        if (this.notifyStatus === 'groupA') message = `您好，您有已配單但未結單`
        else if (this.notifyStatus === 'groupB') message = `您好，您有已結單但未付款`
      } else {
        message = `賣家的話:\n${this.notifyMsg} \n\n`
      }
      // 圖片處理
      let files = document.querySelector("input[type=file]").files
      const result = this.handleImages(files)
      
      if (result.errorSize.length > 0) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert("other", "圖片大小大於 10M")
      }

      if (result.errorType.length > 0) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert("other", "圖片類型不正確")
      }

      let imgFile = null
      if (result.newImages.length > 0) {
        imgFile = result.newImages[0].files
      }
      // 整理傳遞至API資料
      const jsonString = []
      this.selectItems.forEach(item => {
        let msg = ""
        if (this.notifyStatus !== 'general') {
          // 如果是 (預設訊息前面須加名字)
          if (!this.checkIsSetOwnNotifyMsg) msg = `${item.user.name}${message}\n\n`
          else msg = `${message}訂單資訊:\n`
          // 組訂單資訊
          const merchandiseMap = this.notifyOtherData.get(item.id).merchandise
          const totalPrice = this.notifyOtherData.get(item.id).totalPrice
          for (const [merchandiseId, merchandiseValue] of merchandiseMap) {
            console.log(merchandiseValue)
            for (const [styleIds, styleValue] of merchandiseValue.style) {
              msg += `${merchandiseValue.merchandiseName} ${styleValue.styleName} 數量:${styleValue.quantity} 單價:${styleValue.lowerPrice} 小計:${styleValue.subTotal}元\n`
            }
          }
          msg += `總計: ${totalPrice}元(僅參考)`
        } else {
          msg += `\n${message}`
        }

        msg += `\n\n本訊息由管理群【${this.storeInfo.name}】於後台群發通知。`

        // 超過 800 字就分成多段
        const splitResult = this.splitGraph(msg, 800)

        // console.log(splitResult)

        splitResult.forEach((graph, index, arr) => {
          // 若開頭有 N 個 \n 就先全部移除，最後加上一個 \n 就好
          const removeEnter = graph.replace(/^(\\n)+/gm, "")
          const newMsg = "\n" + removeEnter
          
          // 紀錄 api 資料
          let obj = {
            type: 13,
            id: item.id,
            message: newMsg,
          }
          // console.log(newMsg)
          // 如果有圖片，就將圖片放在最後一個訊息內容後面
          if (imgFile !== null && index === arr.length - 1) obj.imgFile = 0 
          
          // 因為後端最後要記錄 notify history 有做一個去頭尾空白的動作，如果去完頭尾的結果是有任一字元的，就給過
          const finalCheck = newMsg.trim()
          if (finalCheck) jsonString.push(obj)
        })
      })
      console.log(jsonString)
      const data = new FormData()
      data.append('jsonString ', JSON.stringify(jsonString))
      data.append('imgFiles ', imgFile)

      // return this.$methods.switchLoading('hide')

      this.$api.line.notify(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          this.SweetAlert(res.code, res.message)
          this.selectItems = []
          this.notifyModal.hide()
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // * OK 取發布 notify 所需資料 API
    getNotitfyOtherInfo() {
      const getNotitfyOtherInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      let ids = []
      this.selectItems.forEach(item => {
        if (this.notifyStatus === 'groupA') ids.push(...item.allocatedButUnCheckOutQuantityMerchOrderIds)
        else ids.push(...item.unPaidBillIds)
      })
      console.log(ids)
      let data = []
      if (this.notifyStatus === 'groupA') {
        data = [{
          ids: ids,
          type: 26,
          methods: '{getMerchandise,getMerchandiseStyles}'
        }]
      } else {
        data = [{
          ids: ids,
          type: 27,
          methods: '{getBillBodies{getMerchOrder{getMerchandise,getMerchandiseStyles}}}'
        }]
      }
      const result = new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          async: true,
          url: getNotitfyOtherInfoApi,
          data: JSON.stringify(data),
          headers: header,
          contentType: 'application/json',
          success: resolve,
          error: reject,
        })
      })
      return result
    },
    // * OK 取發布 notify 所需資料之後的整理
    sortoutNotifyOtherData(item) {
      // 紀錄同買家
      if (!this.notifyOtherData.has(item.buyerId)) {
        this.notifyOtherData.set(item.buyerId, {
          merchandise: new Map(),
          totalPrice: 0
        })
      }
      // 紀錄同商品
      let merchandiseMap = this.notifyOtherData.get(item.buyerId).merchandise
      if (!merchandiseMap.has(item.merchandiseId)) {
        merchandiseMap.set(item.merchandiseId, {
          merchandiseName: item.merchandise.name,
          style: new Map()
        })
      }
      // 紀錄同款式
      let styleMap = merchandiseMap.get(item.merchandiseId).style
      let styleIdStr = ''
      let styleName = ''
      item.merchandiseStyles.forEach((styleItem, index, arr) => {
        styleIdStr += styleItem.id
        styleName += styleItem.name
        if (index < arr.length - 1) {
          styleIdStr += ","
          styleName += ","
        }
      })
      if (!styleMap.has(styleIdStr)) {
        styleMap.set(styleIdStr, {
          styleName: styleName,
          quantity: 0,
          lowerPrice: item.price,
          subTotal: 0 
        })
      }
      styleMap.get(styleIdStr).quantity += item.quantity
      if (styleMap.get(styleIdStr).lowerPrice > item.price) styleMap.get(styleIdStr).lowerPrice = item.price
      styleMap.get(styleIdStr).subTotal += this.$methods.numberToFixed(item.quantity * item.price)
    
      this.notifyOtherData.get(item.buyerId).totalPrice += this.$methods.numberToFixed(item.quantity * item.price)
    },
    // OK 來自<批次結單>子層的通知
    notifyParentFromBatchCheckOut(object) {
      console.log('批次結單:', object)
      console.log('選項:', this.selectItems)
      this.showBatchCheckOutModal = object.showBatchCheckOutModal
      if (object.reloadDataTable) {
        this.updateData("normal", "showBatchCheckOutModal")
      }
    },

    /** 表格搜尋 */
    tableSearch () {
      this.searchGlobal['global'].value = this.tableSearchValue;
    },
  }
}
</script>